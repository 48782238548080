import React from "react"
import styled from "styled-components"
import { themes } from "../styles/colorStyles"

export default function CTAButton() {
  return (
    <Wrapper
      data-drip-embedded-form="254777758"
      action="https://www.getdrip.com/forms/254777758/submissions"
      method="post"
    >
      <SignUp
        name="fields[email]"
        type="email"
        placeholder="Email Address"
      ></SignUp>
      <SubmitWrapper>
        <CTA
          data-drip-attribute="sign-up-button"
          type="submit"
          value="Sign Up"
        />
        <IconArrow src="../images/icons/arrow-thick.svg" />
      </SubmitWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 6px 6px 6px 24px;
  background: #ffffff;
  border: 1px solid rgba(82, 94, 109, 0.5);
  max-width: 640px;
  width: 100%;
  @media (min-width: 1348px) {
    border-radius: 12px;
    width: 100%;
  }
  @media (min-width: 960px) {
    border-radius: 12px;
    max-width: 640px;
    margin: 0 auto;
  }
`

const SignUp = styled.input`
  color: rgba(82, 94, 109, 0.5);
  font-weight: normal;
  font-size: 24px;
  line-height: 180%;
  font-family: "Beatrice";
  width: 100%;
  @media (max-width: 1348px) {
    font-size: 20px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
  }
`
const CTA = styled.input`
  font-family: "Beatrice";
  font-size: 24px;
  line-height: 180%;
  font-weight: 700;
  cursor: pointer;
  color: ${themes.light.ctaTextColor};

  @media (max-width: 1348px) {
    font-size: 20px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
  }
`
const IconArrow = styled.img`
  height: 16px;
  margin-top: -4px;
  fill: ${themes.light.ctaTextColor};
  fill-opacity: 100%;
  @media (min-width: 800px) {
    height: 20px;
  }
`
const SubmitWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6px 40px 4px;
  background: linear-gradient(257.25deg, #f5d562 0.44%, #e03ec6 189.86%);
  border-radius: 4px;

  @media (min-width: 1348px) {
    border-radius: 8px;
  }
  @media (max-width: 800px) {
    padding: 8px 12px 4px;
    width: 120px;
  }
`

/* import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { ctaText } from "../styles/textStyles"

function CTAButton(props) {
  return (
    <Link to="/page-2">
      <Wrapper>
        <Title>{props.title}</Title>
        <Icon src="/images/icons/arrow-right.svg" />
      </Wrapper>
    </Link>
  )
}

export default CTAButton

const Wrapper = styled.div`
  width: 180px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 12px 24px;
  background: #edf8fc;
  border: 1.5px solid #36c5f0;
  border-radius: 8px;

  @media (max-width: 1348px) {
    padding: 8px 16px;
  }
`
const Title = styled(ctaText)``
const Icon = styled.img``
*/
