import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { bodySmall, price } from "../styles/textStyles"
import { themes } from "../styles/colorStyles"

export default function ResourceBlock(props) {
  return (
    <Wrapper>
      <Link to={props.url || "https://dribbble.com/levidjones"}>
        <Shot
          width="346px"
          height="252px"
          src={props.img || "../images/resources/resource1.jpg"}
        />
        <Text>
          <URL>{props.link || "shiftnudge.com"}</URL>
          <Price>from ${props.price || "1,995"}</Price>
        </Text>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 54px;
  @media (max-width: 1360px) {
    margin: 24px 0;
  }
`
const Shot = styled.img`
  border-radius: 8px;
  border: none;
  @media (max-width: 540px) {
    width: 320px;
    height: 240px;
  }
`
const Text = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const URL = styled(bodySmall)`
  font-weight: 500;
  color: ${themes.light.text};
`

const Price = styled(price)`
  display: inline-block;
  font-weight: 500;
  text-align: right;
  color: ${themes.light.textLight};
`
