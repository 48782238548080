import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { bodySmall, title } from "../styles/textStyles"
import { themes } from "../styles/colorStyles"

export default function InspoBlock(props) {
  return (
    <Wrapper>
      <Link to={props.link || "https://dribbble.com/"}>
        <Shot
          width="346px"
          height="252px"
          src={props.shot || "../images/shots/sampleShot.webp"}
        />
        <Designer>
          <ProfileImage
            width="32px"
            height="32px"
            src={props.profile || "../images/avatars/levi.jpg"}
          />
          <Name>{props.name || "Levi Jones"}</Name>
          <TeamText>
            &nbsp;from&nbsp;
            <Team>{props.team || "Tithe.ly"}</Team>
          </TeamText>
        </Designer>
      </Link>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin: 28px 60px;
  @media (max-width: 800px) {
    margin: 40px 0;
  }
`
const Shot = styled.img`
  border-radius: 8px;
  border: none;
  @media (max-width: 540px) {
    width: 320px;
    height: 240px;
  }
`
const Designer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const ProfileImage = styled.img`
  margin-right: 12px;
  border-radius: 50%;
`

const Name = styled(title)``

const TeamText = styled(bodySmall)`
  display: inline-block;
`

const Team = styled(bodySmall)`
  color: ${themes.light.dribbble};
  text-decoration: underline;
  display: inline-block;
`
