import React from "react"
import styled from "styled-components"
import DynamicColumns from "../components/sections/dynamicColumns"
import Layout from "../components/layout/layout"
import SEO from "../components/layout/seo"
import HeroSection from "../components/sections/heroSection"
import NavBar from "../components/sections/navBar"
import ResourcesSection from "../components/sections/resources"
import Features from "../components/sections/features"
import SignUpBar from "../components/sections/signUpBar"

function IndexPage() {
  return (
    <Layout>
      <SEO title="Home" />
      <NavBar />
      <HeroSection />
      <DynamicColumns
        name1="Tran Mau Tri Tam ✪"
        team1="UI8"
        shot1="/images/shots/shot2.webp"
        link1="https://dribbble.com/shots/14735887-Unity-Dashboard-Kit-Dark-mode"
        profile1="/images/avatars/tran.png"
        name2="Stian ◒"
        team2="Unfold"
        shot2="/images/shots/shot3.webp"
        link2="https://dribbble.com/shots/14738175-Borderleap"
        profile2="/images/avatars/stian.png"
        name3="Ghulam Rasool 🚀"
        team3="Cuberto"
        shot3="/images/shots/shot4.webp"
        link3="https://dribbble.com/shots/14715367-Wallet-Dashboard-Design"
        profile3="/images/avatars/ghulam.jpg"
        name4="Talented Designers"
        team4="Tubik"
        shot4="/images/shots/shot5.webp"
        link4="https://dribbble.com/shots/14695171-Web-Editorial-Generation-X-Page"
        profile4="/images/avatars/tubik.png"
        name5="Phil Goodwin ◒"
        team5="Unfold"
        shot5="/images/shots/shot1.webp"
        link5="https://dribbble.com/shots/14721440-Stamped-io-Hero-Concept"
        profile5="/images/avatars/phil.png"
        name6="Alexander Plyuto 🎲"
        team6="Plyuto"
        shot6="/images/shots/shot6.webp"
        link6="https://dribbble.com/shots/14734160-Anypay-POS-hero-section"
        profile6="/images/avatars/plyuto.png"
      />
      <Image src="/images/waves/wave.svg" width="100%" />
      <ResourcesSection
        img1="/images/resources/resource1.jpg"
        url1="https://shiftnudge.com"
        link1="shiftnudge.com"
        price1="1,995"
        img2="/images/resources/resource4.webp"
        url2="https://dannpetty.com/happy-new-ui"
        link2="dannpetty.com"
        price2="397"
        img3="images/resources/resource2.jpg"
        url3="https://nopicnicpress.com/shop/dcit3"
        link3="nopicnicpress.com"
        price3="26"
      />
      <Features />
      <SignUpBar />
    </Layout>
  )
}

const Image = styled.img`
  margin-top: -480px;
`
export default IndexPage
/*
useEffect(() => {
  const script = document.createElement("script")

  script.src = "./drip.js"
  script.async = true

  document.body.appendChild(script)

  return () => {
    document.body.removeChild(script)
  }
}, [])*/
