import styled from "styled-components"
import { themes } from "./colorStyles"

export const h1 = styled.h1`
  font-weight: 400;
  font-size: 60px;
  line-height: 120%;
  font-family: "Beatrice";
  font-weight: 700;
  margin: 40px 0 24px;
  color: ${themes.light.headline};

  @media (max-width: 1360px) {
    font-size: 48px;
  }
  @media (max-width: 800px) {
    font-size: 32px;
  }
`
export const h2 = styled.h2`
  font-weight: bold;
  font-size: 48px;
  line-height: 120%;
  color: ${themes.light.headline};
  @media (max-width: 1360px) {
    font-size: 40px;
  }
  @media (max-width: 800px) {
    font-size: 32px;
  }
`
export const h3 = styled.h3`
  font-size: 32px;
  line-height: 150%;
`
export const title = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: ${themes.light.headline};
  line-height: 140%;
`
export const bodyLarge = styled.p`
  font-weight: normal;
  font-size: 24px;
  line-height: 180%;
  color: ${themes.light.text};
  font-family: "Beatrice", sans-serif;
  @media (max-width: 1348px) {
    font-size: 20px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
  }
`
export const bodyMedium = styled.p`
  font-weight: normal;
  font-size: 20px;
  line-height: 160%;
  color: ${themes.light.text};
  font-family: "Beatrice", sans-serif;
  @media (max-width: 1348px) {
    font-size: 18px;
    padding-top: 12px;
  }
  @media (max-width: 800px) {
    font-size: 16px;
  }
`
export const bodySmall = styled.p`
  font-size: 16px;
`
export const price = styled.p`
  font-size: 14px;
  line-height: 180%;
`

export const textLink = styled.a`
  font-weight: normal;
  font-size: 16px;
  line-height: 160%;
  text-transform: uppercase;
  text-decoration: none;
  color: ${themes.light.ctaTextColor};
`
export const highlight = styled.span`
  background: ${themes.light.button};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-transform: uppercase;
  font-style: italic;
  border: 0px solid;
  border-image-slice: 1;
  border-width: 0;
  display: inline-block;
  height: 62px !important;
  border-bottom-width: 4px;
  border-image-source: linear-gradient(
    257.25deg,
    #f5d562 0.44%,
    #e03ec6 189.86%
  );
  @media (max-width: 1348px) {
    height: 52px !important;
  }
  @media (max-width: 800px) {
    height: 32px !important;
    border-bottom-width: 3px;
  }
`

export const ctaText = styled.p`
  color: ${themes.light.ctaTextColor};
  font-family: "Beatrice", sans-serif;
`
