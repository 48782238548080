import { Link } from "gatsby"
import React from "react"
import styled from "styled-components"

// <MyName>dailyUI</MyName>
// <Beta>Beta</Beta>

function NavBar() {
  return (
    <Link to="/">
      <Wrapper>
        <Logo src="../images/logos/logo.svg" />
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled.p`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0px;
  height: 142px;
  margin: 0;
  padding: 0 72px;
  @media (max-width: 600px) {
    padding: 0 24px;
    height: 108px;
  }
`
const Logo = styled.img``

/*
const MyName = styled.p`
  font-weight: 900;
  font-family: "Beatrice", sans-serif;
`
const Beta = styled.p`
  margin: 0 8px;
  padding: 5px 8px 4px;
  background: linear-gradient(257.25deg, #f5d562 0.44%, #e03ec6 189.86%);
  border-radius: 2px;
  font-family: "Beatrice", sans-serif;
  font-style: italic;
  font-size: 12px;
`*/

export default NavBar
