import React from "react"
import styled from "styled-components"
import { themes } from "../styles/colorStyles"
import { bodyLarge, h2 } from "../styles/textStyles"
import FeatureItem from "./feature"

export default function Features() {
  return (
    <Wrapper>
      <Column>
        <Heading>It’s better and it’s FREE</Heading>
        <Description>— not because it’s free</Description>
      </Column>
      <Column>
        <FeatureList>
          <FeatureItem />
          <FeatureItem
            icon="../images/icons/screen-text.svg"
            text="Save time searching online"
          />
          <FeatureItem
            icon="../images/icons/lightbulb.svg"
            text="Get highly-rated recommendations for tools, tuts, and more to help you grow"
          />
          <FeatureItem
            icon="../images/icons/userID.svg"
            text="Learn about up and coming designers"
          />
          <FeatureItem
            icon="../images/icons/users.svg"
            text="Be the first to hear about new ideas we’re trying"
          />
        </FeatureList>
      </Column>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: ${themes.light.text};
  padding: 124px 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 128px 80px;
  }
  @media (max-width: 800px) {
    padding: 120px 40px;
  }
`
const Column = styled.div`
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
  }
`
const Heading = styled(h2)`
  margin: 0 0 16px;
  max-width: 360px;
  color: white;
  @media (max-width: 960px) {
    max-width: 100%;
    margin-bottom: 8px;
  }
`
const Description = styled(bodyLarge)`
  background: ${themes.light.button};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline;
`
const FeatureList = styled.ul`
  padding: 0;
  @media (max-width: 960px) {
    padding-top: 20px;
  }
`
