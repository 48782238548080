import React from "react"
import styled from "styled-components"
import CTAButton from "../buttons/CTAButton"
import { highlight, bodyMedium, h3 } from "../styles/textStyles"
import { themes } from "../styles/colorStyles"

export default function SignUpBar() {
  return (
    <Wrapper>
      <Column>
        <Heading>
          <Highlight>FREE</Highlight> UI inspiration every day
        </Heading>
        <Description>Start every day feeling inspired</Description>
      </Column>
      <Column>
        <CTAButton />
      </Column>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  background-color: ${themes.light.headline};
  padding: 48px 120px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 960px) {
    flex-direction: column;
    padding: 48px 80px;
  }
  @media (max-width: 800px) {
    padding: 64px 40px 80px;
  }
`
const Column = styled.div`
  width: 50%;
  @media (max-width: 960px) {
    width: 100%;
  }
`
const Heading = styled(h3)`
  margin: 0 0 8px;
  color: white;
  max-width: 540px;
`
const Description = styled(bodyMedium)`
  color: ${themes.light.textLight};
  padding: 0;
  margin: 0;
  @media (max-width: 960px) {
    margin-bottom: 48px;
  }
`
const Highlight = styled(highlight)`
  border-bottom-width: 2px;
  height: 40px !important;
`
