import React from "react"
import styled from "styled-components"
import { themes } from "../styles/colorStyles"
import { bodyLarge, h2 } from "../styles/textStyles"
import InspoWrapper from "./inspoWrapper"

export default function DynamicColumns(props) {
  return (
    <Wrapper>
      <WrapperColumn>
        <Heading>Stop looking for inspiration</Heading>
        <Description>— Let it come to you</Description>
        <NewDesc>New Everyday</NewDesc>
      </WrapperColumn>
      <InspoWrapper
        name1={props.name1 || "Eddie Lobanivsky"}
        team1={props.team1 || "Unfold"}
        shot1={props.shot1 || "/images/shots/shot2.webp"}
        link1={props.link1}
        profile1={props.profile1}
        name2={props.name2 || "Eddie Lobanivsky"}
        team2={props.team2 || "Unfold"}
        shot2={props.shot2 || "/images/shots/shot2.webp"}
        link2={props.link2}
        profile2={props.profile2}
        name3={props.name3 || "Eddie Lobanivsky"}
        team3={props.team3 || "Unfold"}
        shot3={props.shot3 || "/images/shots/shot2.webp"}
        link3={props.link3}
        profile3={props.profile3}
      />
      <Shift>
        <InspoWrapper
          name1={props.name4 || "Eddie Lobanivsky"}
          team1={props.team4 || "Unfold"}
          shot1={props.shot4 || "/images/shots/shot2.webp"}
          link1={props.link4}
          profile1={props.profile4}
          name2={props.name5 || "Eddie Lobanivsky"}
          team2={props.team5 || "Unfold"}
          shot2={props.shot5 || "/images/shots/shot2.webp"}
          link2={props.link5}
          profile2={props.profile5}
          name3={props.name6 || "Eddie Lobanivsky"}
          team3={props.team6 || "Unfold"}
          shot3={props.shot6 || "/images/shots/shot2.webp"}
          link3={props.link6}
          profile3={props.profile6}
        />
      </Shift>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 0 120px;
  align-items: flex-start;
  overflow-x: hidden;
  height: 1360px;
  @media (max-width: 1360px) {
    height: 1400px;
    padding: 0 80px;
  }
  @media (max-width: 1200px) {
    width: 100%;
  }
  @media (max-width: 1080px) {
    padding: 0 32px;
    margin: 0 auto;
  }
  @media (max-width: 800px) {
    flex-direction: column;
    padding: 0 20px;
    height: 2780px;
    margin: 0 auto;
    max-width: 360px;
  }
  @media (max-width: 540px) {
    max-width: 320px;
  }
`
const WrapperColumn = styled.div`
  padding: 120px 0 0;
  width: 42%;

  @media (max-width: 1200px) {
    width: 20%;
  }
  @media (max-width: 960px) {
    width: 20%;
  }
  @media (max-width: 800px) {
    width: 100%;
  }
`
const Heading = styled(h2)`
  margin-bottom: 16px;
  @media (max-width: 960px) {
    font-size: 24px;
  }
  @media (max-width: 800px) {
    font-size: 32px;
  }
`
const Description = styled(bodyLarge)`
  margin: 0 0 348px;
  @media (max-width: 960px) {
    font-size: 16px;
  }
  @media (max-width: 800px) {
    margin: 0 0 24px;
  }
`
const NewDesc = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  background: ${themes.light.button};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  width: 180px;
`
const Shift = styled.div`
  padding: 92px 0 0 0;
  @media (max-width: 800px) {
    padding: 0;
  }
`

/*
     <Wrapper>
<InspoBlock
name="Ghulam Rasool 🚀"
team="Cuberto"
shot="/images/shots/shot4.webp"
/>
<InspoBlock team="Tubik" shot="/images/shots/shot5.webp" />
<InspoBlock name="Alexander Plyuto 🎲" shot="/images/shots/shot6.webp" />
</Wrapper> */
