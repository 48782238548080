import React from "react"
import styled from "styled-components"
import { bodyLarge, bodySmall, h1, highlight } from "../styles/textStyles"
import CTAButton from "../buttons/CTAButton"
import { themes } from "../styles/colorStyles"

function HeroSection() {
  return (
    <Wrapper>
      <Header>
        <Highlight>Free</Highlight> UI Inspiration delivered to your inbox every
        day.
      </Header>
      <Description>
        Sign up to stay inspired, learn, and grow with freelancers, in-house
        designers, and startup owners from all over the world.
      </Description>
      <CTAWrapper>
        <CTAButton />
      </CTAWrapper>
      <LaunchDate>Launching January 4, 2021</LaunchDate>
    </Wrapper>
  )
}
const Wrapper = styled.div`
  margin: 60px auto 100px;
  width: 64%;
  max-width: 1234px;
  text-align: center;
  @media (max-width: 640px) {
    width: 100%;
    margin: 20px;
  }
  @media (max-width: 640px) {
    width: 88%;
    margin: 0px auto;
  }
`
const Highlight = styled(highlight)``
const Header = styled(h1)`
  position: relative;
  text-align: center;
`
const Description = styled(bodyLarge)`
  margin-bottom: 32px !important;
`

const CTAWrapper = styled.form`
  width: 100%;
  /*width: 600px;
  margin: 0 auto;
  @media (max-width: 800px) {
    width: 420px;
  }
  @media (max-width: 480px) {
    width: 360px;
  }*/
`

const LaunchDate = styled(bodySmall)`
  font-weight: 700;
  text-transform: uppercase;
  background: ${themes.light.button};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 24px auto;
  width: 280px;
`

export default HeroSection
