import React from "react"
import styled from "styled-components"
import { h2, bodyLarge } from "../styles/textStyles"
import ResourceBlock from "./resourceBlock"
import { themes } from "../styles/colorStyles"

export default function ResourcesSection(props) {
  return (
    <Wrapper>
      <Heading>Take it from us</Heading>
      <Description>
        We find and digest the top courses and content so all you have to is
        take our recommendation
      </Description>
      <Container>
        <Tag>Some of Our Favs</Tag>
        <Blocks>
          {" "}
          <ResourceBlock
            link={props.link2}
            url={props.url2}
            price={props.price2}
            img={props.img2}
          />
          <ResourceBlock
            link={props.link3}
            url={props.url3}
            price={props.price3}
            img={props.img3}
          />
          <ResourceBlock
            link={props.link1}
            url={props.url1}
            price={props.price1}
            img={props.img1}
          />
        </Blocks>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  background-color: #d7e3f2;
  padding: 0 0 80px;
  margin: -40px 0 0;
  @media (max-width: 800px) {
    margin: -16px auto 0;
    padding: 80px;
  }
  @media (max-width: 540px) {
    padding: 60px 20px;
  }
`

const Heading = styled(h2)`
  max-width: 546px;
  text-align: center;
  margin: 0 auto 16px;
`
const Description = styled(bodyLarge)`
  max-width: 546px;
  text-align: center;
  margin: 0 auto 96px;
`
const Container = styled.div``

const Tag = styled.p`
  font-weight: 700;
  text-transform: uppercase;
  background: ${themes.light.button};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
  position: absolute;
  right: 276px;
  @media (min-width: 0px) {
    display: none;
  }
`
const Blocks = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: center;
  @media (max-width: 1360px) {
    flex-direction: column;
    margin: 0 auto;
    width: 346px;
  }
  @media (max-width: 540px) {
    width: 320px;
  }
`
