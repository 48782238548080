export const themes = {
  light: {
    headline: "rgba(33,38,51,1)",
    text: "rgba(84,94,108,1)",
    textLight: "rgba(132,144,159,1)",
    background: "white",
    ctaTextColor: "rgba(9,153,202,1)",
    button: "linear-gradient(256.06deg, #F5D562 -32.42%, #E03EC6 190.49%)",
    dribbble: "#ea4c89",
  },
  dark: {
    headline: "rgba(255,255,244,1)",
    text: "rgba(255,255,244,.7)",
    background: "#0f0f0f",
    ctaTextColor: "rgba(9,153,202,1)",
  },
}
