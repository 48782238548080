import React from "react"
import styled from "styled-components"
import { bodyMedium } from "../styles/textStyles"

export default function FeatureItem(props) {
  return (
    <Wrapper>
      <FeatureWrapper>
        <FeatureIconWrapper>
          <FeatureIcon src={props.icon || "../images/icons/sand-clock.svg"} />
        </FeatureIconWrapper>
        <FeatureText>{props.text || "Save time searching online"}</FeatureText>
      </FeatureWrapper>
    </Wrapper>
  )
}
const Wrapper = styled.div``

const FeatureWrapper = styled.li`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 0 32px;
  height: 48px;
  @media (max-width: 960px) {
    margin-bottom: 12px;
    height: auto;
  }
`
const FeatureIconWrapper = styled.div`
  background-color: #495564;
  padding: 12px;
  border-radius: 48px;
  width: 24px;
  height: 24px;
  margin: 0 20px 0 0;
  @media (max-width: 960px) {
  }
`
const FeatureIcon = styled.img``
const FeatureText = styled(bodyMedium)`
  color: white;
  padding: 0;
  @media (max-width: 960px) {
    font-size: 16px;
    line-height: 160%;
  }
`
