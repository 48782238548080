import React from "react"
import InspoBlock from "./inspoBlock"
import styled from "styled-components"

export default function InspoWrapper(props) {
  return (
    <Wrapper>
      <InspoBlock
        name={props.name1 || "Stian"}
        profile={props.profile1}
        link={props.link1}
        team={props.team1 || "Unfold"}
        shot={props.shot1 || "/images/shots/shot1.webp"}
      />
      <InspoBlock
        name={props.name2 || "Tran Mau Tri Tam ✪"}
        profile={props.profile2}
        link={props.link2}
        team={props.team2 || "UI8"}
        shot={props.shot2 || "/images/shots/shot2.webp"}
      />
      <InspoBlock
        name={props.name3 || "Stian"}
        profile={props.profile3}
        link={props.link3}
        team={props.team3 || "Unfold"}
        shot={props.shot3 || "/images/shots/shot3.webp"}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 346px;
  height: 1036px;
  margin: 0 32px;
  @media (max-width: 1200px) {
    transform: scale(0.8);
    margin: 0;
  }
  @media (max-width: 960px) {
    transform: scale(0.72);
    margin: 0;
    width: 320px;
  }
  @media (max-width: 800px) {
    margin: 60px 0;
    display: block;
    transform: scale(1);
  }
  @media (max-width: 540px) {
    margin: 12px 0;
  }
`
